.onesvg {
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 0px;
  transition: 0.5s;

  justify-content: center;

  //    max-height: 80px;

  svg {
    width: calc(135px + 3.5vh) !important;
    height: calc(100.341%) !important;
    transform: scale(1);
    transition: 0.5s;
  }
}
.onesvg:hover {
  margin-top: 5px;
  margin-bottom: 5px;
  svg {
    transform: scale(1.1);
    svg {
    }
    g {
      g {
        path {
          display: flex;
        }
      }
    }
    g:nth-child(2) {
      g {
        path:nth-last-child(3) {
          display: flex !important;
        }
        g {
          path:nth-last-child(3) {
            display: flex !important;
          }
        }
      }
    }
  }
}
