.navbar {
  z-index: 100;
  position: fixed !important;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: black;

  .div {
    margin: 0;
    padding: 0;
  }
  .col-12 {
    margin: 0;
    padding: 0;
  }
  figure {
  }
  .bar {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    background-color: black;
  }

  .search {
    border: 2px solid white;
    border-radius: 100px;
    height: min-content;
    width: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    img {
      width: 25px;
      height: 25px;
      padding: 6px;
      // cursor: url("../../images/cursor/pointer.svg") 15 15, pointer;
      cursor: pointer;
    }
    .input {
      position: relative;
      border: 0;
      width: 0px;
      height: 25px;
      min-width: 0px;
      margin: 0;
      padding: 0px;
      background-color: transparent;
      color: white;
      outline: none;
      transition: 0.3s;
      //   animation: clickedclose 0.4s ease;
    }
    @keyframes clicked {
      0% {
        width: 0px;
        padding-left: 0px;
        padding-right: 0px;
      }
      50% {
        padding-left: 15px;
        padding-right: 5px;
      }

      100% {
        width: 150px;
        padding-left: 15px;
        padding-right: 5px;
      }
    }
    @keyframes clickedclose {
      0% {
        width: 150px;
        padding-left: 15px;
        padding-right: 5px;
      }
      50% {
        padding-left: 0px;
        padding-right: 0px;
      }

      100% {
        width: 0px;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    .clicked {
      width: 200px;
      //   animation: clicked 0.4s ease;
      padding-left: 15px;
      padding-right: 5px;
    }
  }

  .button {
    width: fit-content;
    img {
      height: 51px;
      margin-top: 3px;
    }
  }
  .menubtn {
    width: fit-content;
    margin-left: 25px;
    img {
      width: 30px;
    }
  }
}




.navbar_btnlanguages {
  margin-right: 14px;
  padding: 0 !important;
  &_img {
    height: 24px;
  }
}
.navbar_btnlanguages2 {
  margin-right: 30px;
  padding: 0 !important;
  margin-top: 10px !important;
  &_img {
    height: 24px;
  }
  button {
    img {
      height: 37px;
    }
    img.navbar_more {
      height: 27px;
    }
  }
}
.langsearch{
  display: flex;
  flex-wrap: nowrap;
}
.bigtitle{
  position: absolute;
  left: calc(8.333333% + 20px);
  p{
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
}
.changelanguage{
    border: none;
    width: 53px;
    padding: 0;
    font-family: 'poppins';
    position: relative;
    background-color: black;
    color: white;
    img{
        width: 10px;
        margin-left: 7px;
        background-color: transparent;
        filter: invert(1);
    }
    .rotate{
      transform: rotate(180deg);
    }
    .button{
      border: none;
      padding: 0;
      margin: 0;
      width: 100%;


      display: none;
      font-weight: 400;
      position: absolute;
      top: 30px;
    //  padding-top: 10px;
      height: 37px;
      p{
        font-size: calc(16.5px + 0.05vw);
        margin: 0;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        align-items: center;
        padding-left: 11px;
        display: flex;
      }
    }
    .view{
      display: flex;
    }
    button:hover{
      font-weight: 500;
    }
}