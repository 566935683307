.section_teintes {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  .div {
    margin: 0;
    padding: 0;
  }
  .col-12 {
    margin: 0;
    padding: 0;
  }
  .section {
    margin: 0;
    padding: 0;
    .title {
      height: calc(55px + 3vh);
      display: flex;
      align-items: flex-start;
    }
  }
  .setting_img01 {
    figure {
      width: 100%;
      height: 32vh;
      img {
        width: 100%;
      }
    }
  }
  .setting_img02 {
    figure {
      width: 100%;
      height: 4vh;
      img {
        width: 101%;
        margin-left: -0.5%;
      }
    }
  }
  table {
    display: inline-block;
    width: 100%;
  }

  th {
    text-transform: uppercase;
    border-bottom: solid 1px white;
    border-right: solid 1px white;
    border-top: solid 1px white;
    padding: 0 2% 0 2%;
    width: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 1.05rem !important;
  }
  tr {
    width: 100%;
    display: flex;
  }
  td {
    width: 50%;
    border-bottom: solid 1px white;
    border-right: solid 1px white;

    padding: 0 2% 0 2%;
    padding-bottom: 3px;
    padding-top: 3px;
    font-size: 1.05rem !important;
  }
  td:nth-child(1) {
    color: var(--blue);
    border-left: solid 1px white;
  }
  th:nth-child(1) {
    border-left: solid 1px white;
  }
}
.tbsection{
  flex: 0 0 30%;
  max-width: 30%;
}
figure{
  margin: 0 0 1.5vw;
}