.section_general {
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .div {
    margin: 0;
    padding: 0;
  }
  .col-12 {
    margin: 0;
    padding: 0;
  }
  .section {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    .content {
      margin: 0;
      padding: 0;
    }
  }

  ul {
    padding: 0;
    li:nth-child(1) {
      background: url("../img/big_point.svg") no-repeat 2px 1.5px;
      list-style: none;
      margin: 0;
    }
  }

  ul {
    li {
      background: url("../img/point.svg") no-repeat 9px 10px;
      padding: 0px 2% 2% 16%;
      list-style: none;
      margin: 0;
    }
  }

  .none {
    li:nth-child(1) {
      background: url("../img/icon_PDF.svg") no-repeat 0px 0px;
      padding: 0px 2% 2% 16%;
      list-style: none;
      margin: 0;
    }

    li:nth-child(2) {
      background: url("../img/icon_IMG.svg") no-repeat -5px 4px;
      padding: 0px 2% 2% 16%;
      list-style: none;
      margin: 0;
      background-size: 45px;
    }

    li:nth-child(3) {
      background: url("../img/icon_TEXT.svg") no-repeat -3px 1px;
      padding: 0px 2% 2% 16%;
      list-style: none;
      margin: 0;
      background-size: 47px;
    }
    li:nth-child(4) {
      background: url("../img/maxsize.svg") no-repeat 0px 0px;
      padding: 0px 2% 2% 16%;
      list-style: none;
      margin: 0;
      background-size: 29px;
      background-position: 3px 0px;
    }
    li:nth-child(5) {
      background: url("../img/Warning.svg") no-repeat 0px 0px;
      padding: 0px 2% 2% 16%;
      list-style: none;
      margin: 0;
      background-size: 38px;
      background-position: 0px 0px;
    }

    p {
      font-size: 0.85rem;
    }
  }

  .section {
    font-family: "Worksans";
    font-size: 1.1rem;
    font-weight: 400;
  }
  .box {
    border: solid 0.5px;
    display: inline-block;
    padding: 1% 2%;
    border-radius: 2px;
  }
  .margintop {
    margin-top: calc(7px + 2vh);
  }
  u {
    cursor: pointer;
    transition: 0.1s;
  }
  u:hover {
    color: var(--blue);
  }
}
.modalimg{
  position: fixed;
  width: calc(100vw);
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 100000;
  top: 60px;
  left: 0;
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.247);
  .modal{
    width: 57vw;
    min-width: 700px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;


    .image{
      width: 100%;
    }
    svg{
      width: 28px;
      height: 28px;
      position: absolute;
      top: -20px;
      right: -40px;
      cursor: pointer;
      z-index: 10;
      g{
        path:last-child{
          fill: white;
          transition: 0.1s;
        }
      }
    }
    svg:hover{

      g{
        path:last-child{
          fill: var(--blue);
        }
      }
    }
  }
  .onclick{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

}
.notvisibleexport{
  display: none;
}