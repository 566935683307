.section01 {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  .div {
    margin: 0;
    padding: 0;
  }
  .col-12 {
    margin: 0;
    padding: 0;
  }
  .section {
    margin: 0;
    padding: 0;
    .title {
      height: calc(55px + 3vh);
      display: flex;
      align-items: flex-start;
    }
  }

  .titlebis {
    display: flex;
    justify-content: space-between;
  }
  .setting_img01 {
    figure {
      width: 100%;
      // height: 40vh;
      margin: 0;
      img {
        width: 100%;
      }
    }
  }
  .setting_imgs {
    figure {
      width: 100%;
      //  height: 25vh;
      margin: 0;
      position: absolute;
      top: 0;
      left: -12vw;
      z-index: 0;
      img {
        width: 70%;
        position: absolute;
        z-index: 2;
      }
    }
  }
  .setting_imgh {
    figure {
      width: 100%;
      //    height: 40vh !important;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: 0.2s;
      img {
        width: 100%;
      }
    }
    figure:hover {
      opacity: 1;
    }
  }
}

.menu {
  height: 100%;
  left: 0;
  top: 0;
  position: absolute !important;
  display: flex;
  align-items: center;
  z-index: 2;
}
