.section_sommaire {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  .div {
    margin: 0;
    padding: 0;
  }
  .col-12 {
    margin: 0;
    padding: 0;
  }
  .section {
    margin: 0;
    padding: 0;
    .title {
      height: calc(55px + 3vh);
      display: flex;
      align-items: flex-start;
    }
    .menu {
      top: -5.5vh;
    }
  }

  .titlebis {
    display: flex;
    justify-content: space-between;
  }
  ul {
    padding: 0;
  }
  li {
    list-style: none;

    p:nth-child(2) {
      margin-left: 15%;
      font-size: 1.5rem;
    }
  }
  .list {
    display: flex;
    align-items: center;
    margin-bottom: 4%;
  }

  .number {
    display: flex;
    border: solid 1px white;
    border-radius: 100%;
    width: calc(15px + 1vw);
    height: calc(15px + 1vw);
    align-items: center;
    justify-content: center;
    font-weight: 500s;
  }
  .setting_img01 {
    figure {
      width: 100%;
      height: 50vh;
      margin: 0;
      img {
        height: 100%;
        margin-top: -5vh;
      }
    }
  }
  .setting_img02 {
    figure {
      width: 100%;
      height: 4vh;
      background-color: pink;
    }
  }
}
