.onesvg {
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 0px;
  transition: 0.5s;

  justify-content: center;

  //    max-height: 80px;

  svg {
    width: calc(120px + 2.5%) !important;
    height: calc(100.341%) !important;
    transform: scale(1);
    transition: 0.5s;
  }

  svg {
  }
  g {
    g {
      path {
        display: none;
      }
    }
  }
}
.onesvg:hover {
  margin-top: 5px;
  margin-bottom: 5px;
  svg {
    transform: scale(1.1);
    svg {
    }
    g {
      g {
        path {
          display: flex;
        }
      }
    }
    g:nth-child(2) {
      g {
        path:nth-last-child(3) {
          display: flex !important;
        }
        g {
          path:nth-last-child(3) {
            display: flex !important;
          }
        }
      }
    }
  }
}

.firstsvg {
  svg {
    g:nth-child(1) {
      g {
        path {
          display: flex !important;
        }
        line {
          display: flex !important;
        }
      }
    }
    g:nth-child(3) {
      g {
        path {
          display: flex !important;
        }
        line {
          display: flex !important;
        }
      }
    }
    g:nth-child(2) {
      g {
        path:nth-last-child(3) {
          display: flex !important;
        }
        g {
          path:nth-last-child(3) {
            display: none !important;
          }
        }
      }
    }
  }
}

.selected {
  svg {
    svg {
    }
    g {
      path {
        stroke: var(--blue);
      }
      line {
        stroke: var(--blue);
      }
      g {
        path {
          display: flex !important;
          fill: var(--blue);
        }
        g {
          g {
            g {
              path:nth-child(2) {
                fill: #000000;
                stroke: rgb(13, 54, 116);
              }
            }
          }
        }
      }
    }

    g:nth-child(2) {
      g {
        path:nth-last-child(3) {
          display: flex !important;
          fill: var(--blue);
        }
        g {
          path:nth-last-child(3) {
            display: flex !important;
            fill: var(--blue);
          }
        }
      }
    }
  }
}
